import { json, useLoaderData } from '@remix-run/react';
import { type MetaFunction, type LoaderFunctionArgs } from '@remix-run/node';

import ErrorNotFound from '#app/components/error-not-found';
import Footer from '#app/components/footer';
import NewsletterBar from '#app/components/newsletter-bar';
import TopBar from '#app/components/top-bar';
import { cn } from '#app/utils/misc';
import { getNavData } from '#app/models/nav.server';
import { retourRedirect } from '#app/models/general.server';
import { getEnv } from '#app/utils/env.server';

export async function loader({ request }: LoaderFunctionArgs) {
  const navResponse = await getNavData();
  const {
    data: { header, footer, general },
  } = navResponse;

  const envVars = getEnv();
  const url = new URL(request.url);
  const pathname = url.pathname;

  const {
    data: { retourRedirectData },
  } = await retourRedirect({ uri: pathname });

  if (
    retourRedirectData &&
    retourRedirectData.redirectDestUrl &&
    retourRedirectData.redirectHttpCode
  ) {
    throw new Response(null, {
      status: parseInt(retourRedirectData.redirectHttpCode),
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Location: retourRedirectData.redirectDestUrl,
      },
    });
  }

  return json(
    {
      header,
      footer,
      general,
      envVars,
    },
    { status: 404 },
  );
}

export const meta: MetaFunction = () => {
  return [{ title: `Page Not Found | VPT, Inc.` }];
};

export default function NotFound() {
  const { header, footer, general, envVars } = useLoaderData<typeof loader>();

  return (
    <div className="grid min-h-full grid-cols-[100%]">
      <TopBar data={header} notification={general} envVars={envVars} />
      <main
        className={cn(
          'relative bg-white',
          general.enableNotificationBar ? 'top-[116px]' : 'top-[66px]',
        )}
      >
        <ErrorNotFound />
      </main>
      <NewsletterBar />
      <Footer data={footer} />
    </div>
  );
}
